<template>
  <div class="settings-container">
    <v-btn
      color="primary"
      outlined
      depressed
      :ripple="false"
      style="width: fit-content; background-color: white;"
      @click="changeRoute('Settings')">
      <v-icon>
        mdi-chevron-left
      </v-icon>
      <span>
        ย้อนกลับ
      </span>
    </v-btn>
    <div class="settings-wrapper rounded-lg">
      <div
        v-for="menu in menus"
        :key="menu.to"
        class="px-8 py-4 d-flex flex-row justify-space-between align-center setting-item"
        style="cursor: pointer;"
        @click="changeRoute(menu.to)">
        <span>
          {{ menu.title }}
        </span>
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    menus: [
      {
        to: 'SettingsContinent',
        title: 'ทวีป-ประเทศ'
      },
      {
        to: 'SettingsTag',
        title: 'แท็กแพ็กเกจทัวร์'
      },
      {
        to: 'SettingsTourType',
        title: 'ประเภททัวร์'
      },
      {
        to: 'SettingsResidentType',
        title: 'ประเภทที่พัก'
      },
      {
        to: 'SettingsAirline',
        title: 'สายการบิน'
      },
      {
        to: 'SettingsAmenity',
        title: 'สิ่งอำนวยความสะดวก'
      },
      {
        to: 'SettingsServiceRate',
        title: 'อัตราค่าบริการ'
      },
      {
        to: 'SettingsCurrency',
        title: 'สกุลเงิน'
      }
    ]
  }),
  methods: {
    changeRoute (to) {
      this.$router.push({ name: to })
    }
  }
}
</script>

<style scoped>
.settings-container {
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.settings-wrapper {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.setting-item {
  border-bottom: 1px solid #E0E0E0;
  transition: all;
  transition-duration: 150ms;
}
.setting-item:first-child {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.setting-item:last-child {
  border-bottom: none;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.setting-item:hover {
  background-color: #026EAA;
  color: white;
}
</style>
